<form [formGroup]="form" class="from-grid grid">
    <div class="col-12 sm:col-6">
        <div class="field">
            <label for="type">Исполнитель<span class="required">*</span></label>
            <p-dropdown styleClass="w-full" [options]="counterparties" formControlName="contractorCounterpartyId"
                optionLabel="name" optionValue="id" placeholder="Выберите исполнителя" [filter]="true"
                [filterPlaceholder]="'Поиск по названию'">
                <ng-template let-model pTemplate="item">
                    <div class="text-muted text-small">
                        {{model.type.name}}
                    </div>
                    <div>{{model.name}}</div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>

    <div class="col-12 sm:col-6">
        <div class="field">
            <label for="type">Заказчик<span class="required">*</span></label>
            <p-dropdown styleClass="w-full" [options]="counterparties" formControlName="clientCounterpartyId"
                optionLabel="name" optionValue="id" placeholder="Выберите заказчика" [filter]="true"
                [filterPlaceholder]="'Поиск по названию'">
                <ng-template let-model pTemplate="item">
                    <div class="text-muted text-small">
                        {{model.type.name}}
                    </div>
                    <div>{{model.name}}</div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>

    <div class="col-12 sm:col-6">
        <div class="field">
            <label for="type">Тип договора<span class="required">*</span></label>
            <p-dropdown styleClass="w-full" [options]="types" formControlName="type" optionLabel="name"
                optionValue="code" placeholder="Выберите тип договора">

            </p-dropdown>
        </div>
    </div>

    <div class="col-12 sm:col-6">
        <div class="field">
            <label for="subject">Предмет договора<span class="required">*</span></label>
            <p-dropdown styleClass="w-full" [options]="subjects" formControlName="subject" optionLabel="name"
                optionValue="code" placeholder="Выберите предмет договора">

            </p-dropdown>
        </div>
    </div>

    <div *ngIf="isAdditionalContract" class="col-12">
        <div class="field">
            <label for="parentId">Родительский договор<span class="required">*</span></label>
            <p-dropdown styleClass="w-full" [options]="contracts" formControlName="parentId"
                optionLabel="serialNumber" optionValue="id" placeholder="Выберите родительский договор" [filter]="true"
                [filterPlaceholder]="'Поиск по номеру договора'">
                <ng-template let-model pTemplate="item">
                    <div *ngIf="model.client" class="text-muted text-small">
                        {{model.client.type.name}} {{model.client.name}} (ИНН: {{model.client.inn}})
                    </div>
                    <div>
                        {{model.type.name}} <strong>{{model.serialNumber}}</strong> от <span class="text-muted">{{model.dateOfConclusion}}</span>
                    </div>
                    <div class="text-small">
                        {{model.amount|number}} руб.
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>

    <div class="col-12">
        <div class="field">
            <label for="serialNumber">Номер договора<span class="required">*</span></label>
            <input id="serialNumber" formControlName="serialNumber" type="text" class="w-full"
                placeholder="Введите номер договора" pInputText />
        </div>
    </div>

    <div class="col-12 md:col-6">
        <div class="field">
            <label for="dateOfConclusion">Дата заключения<span class="required">*</span></label>

            <p-calendar styleClass="w-full" [touchUI]="true" formControlName="dateOfConclusion" dateFormat="yy-mm-dd"
                placeholder="Выберите дата заключения"></p-calendar>
        </div>
    </div>

    <div class="col-12 md:col-6">
        <div class="field">
            <label for="serialNumber">Сумма договора<span class="required">*</span></label>
            <input id="serialNumber" formControlName="amount" type="text" class="w-full"
                placeholder="Укажите сумму договора в рублях" pInputText />
        </div>
    </div>

    <div class="col-12">
        <div class="field">

            <ng-container *ngIf="isCreateMode; else isUpdateMode">
                <div class="text-muted">
                    Креативы для маркировки можно будет добавить после создания договора
                </div>
            </ng-container>
            <ng-template #isUpdateMode>
                <p-table [value]="contractCreatives">
                    <ng-template pTemplate="caption">
                        <div class="flex align-items-center justify-content-between">
                            <div>
                                <h4>Маркировка креативов</h4>
                            </div>
                            <p-button styleClass="p-button-text" (click)="AddItem()" label="Добавить креатив"
                                icon="pi pi-plus"></p-button>
                        </div>
                    </ng-template>
    
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 350px">Креатив</th>
                            <th>Маркер</th>
                            <th style="width: 250px"></th>
                        </tr>
                    </ng-template>
    
                    <ng-template pTemplate="body" let-model>
                        <tr>
                            <td rowspan="2" style="border-bottom-width: 3px;">
                                <p-dropdown [disabled]="model.isRemoved || model.id" styleClass="w-full"
                                    [(ngModel)]="model.creativeId" [ngModelOptions]="{standalone: true}"
                                    [options]="creatives" optionLabel="name" optionValue="id" optionDisabled="used"
                                    placeholder="Выберите креатив" [filter]="true"
                                    [filterPlaceholder]="'Поиск по названию'">
                                    <ng-template let-model pTemplate="item">
                                        <div [pTooltip]="model.description">
                                            <div class="text-muted text-small">
                                                {{model.form.name}} / {{model.campaignType.name}}
                                            </div>
                                            <div>{{model.name}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>

                                <div *ngIf="model.createdAt" class="mt-3 text-small text-muted">Привязан к договору {{model.createdAt |date}}</div>

                            </td>
    
                            <td>
                                <ng-container *ngIf="model.id; else noIdOnCreative">
                                    <app-sync-button 
                                    [erirId]="model.erirId"
                                    [isAcceptedByErir]="model.isAcceptedByErir" 
                                    [isSyncing]="model.isSyncing"
                                    [isSynced]="model.isSynced"
                                    (onClicked)="SyncClick(model)"></app-sync-button>
                                </ng-container>
                                <ng-template #noIdOnCreative>
                                    <div class="text-center text-muted text-small">
                                        Получить маркер можно будет после сохранения изменений
                                    </div>
                                </ng-template>
                            </td>
    
                            <td>
                                <ng-container *ngIf="model.isRemoved; else notRemoved">
                                    <p-button styleClass="p-button-text w-full" (click)="RestoreItem(model)" icon="pi pi-times" label="Вернуть"></p-button>
                                </ng-container>
                                <ng-template #notRemoved>
                                    <p-button styleClass="p-button-danger p-button-text w-full" (click)="RemoveItem(model)" icon="pi pi-times" label="Убрать"></p-button>
                                </ng-template>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2" style="border-bottom-width: 3px;">
                                <div class="ml-auto">
                                    <ng-container *ngIf="model.marker; else noMarker">
                                        <span class="text-muted">Маркер:</span> <span class="ml-3" style="overflow-wrap: anywhere;">{{model.marker}}</span>
                                        <i class="pi pi-copy ml-2" style="cursor: pointer;" pTooltip="Нажмите, чтобы копировать маркер" ngxClipboard [cbContent]="model.marker" ></i>
                                    </ng-container>
                                    <ng-template #noMarker>
                                        <div class="text-muted text-small">
                                            <ng-container *ngIf="!model.id; else notYetCreated">
                                                Здесь будет отображен маркер креатива, после его получения
                                            </ng-container>
                                            <ng-template #notYetCreated>
                                                Маркер ещё не получен
                                            </ng-template>

                                        </div>
                                    </ng-template>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-template>
        </div>
    </div>


    <div class="col-12">
        <div class="field flex">
            <button pButton type="button" [disabled]="isInvalid" [label]="isCreateMode ? 'Создать' : 'Сохранить'"
                class="ml-auto" [loading]="isBusy" (click)="Submit()"></button>
        </div>
    </div>
</form>